/**
 * All Custom Blocks scripts required should be included here.
 *
 * This is the file that Webpack is compiling into blocks.[hash].js
 */
import "../../pubstack/assets/blocks.js"; // load first

import "./sass/blocks.scss";
import VideoHero from "./js/blocks/video-hero";
import "./js/blocks/gallery-shows";
import bgVideo from "./js/blocks/bg-video"; // 1KB
import galleryOverlay from "./js/blocks/gallery-overlay"; // 13KB
import CultureContentHub from "./js/culture-content-hub";

bgVideo();
VideoHero.init();
galleryOverlay();
CultureContentHub.init();
