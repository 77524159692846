import extScriptLoaded from "../utils/script-loaded";

extScriptLoaded(
	() => typeof Swiper !== "undefined",
	() => {
		console.log("ready", typeof Swiper);
		new Swiper('[data-js="ess-feature-shows-swiper"]', {
			slidesPerView: 2,
			spaceBetween: 10,
			loop: true,
			centeredSlides: true,
			lazy: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				768: {
					slidesPerView: 4,
				},
			},
		});
	}
);
